<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
      <v-col cols="12" :sm="step === 2 ? '11' : '12'">
        <base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
			<v-col cols="12" sm="1" align="center">
        <base-btn v-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
    </v-row>

		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">
						<v-form ref="form">
							<v-row justify="center" no-gutters>
								<v-col cols="12" sm="3" md="3" lg="3" xl="3">
									<base-select v-model="editedSearchItem.branchOfficeId" :items="branchOffices" label="Sucursal" 
									:rules="[commonValRules.required]" required></base-select>
								</v-col>
								<v-col cols="12" sm="4" md="3" lg="3" xl="3" class="mx-0 mx-sm-3">
									<base-date-range-picker-dialog @range="dateRange" :rules="[commonValRules.required]" required/>
								</v-col>
								<v-col cols="12" sm="4" md="3" lg="3" xl="3">
									<base-select v-model="editedSearchItem.providerId" :items="providers" label="Proveedor" 
									clearable></base-select>
								</v-col>
								
								<v-col cols="12" sm="2" md="2" lg="2" xl="2" align="center">
									<base-btn dark color="teal" class="mt-2" @click="toList()" icon="mdi-table-search" btnText="Buscar" :loading="loadingSearchForm"></base-btn>
								</v-col>
							</v-row>
						</v-form>

						<v-row>
							<v-col cols="12">
								<base-data-table 
									:loading="loadingSearchForm"
									loading-text="Cargando datos..."
									v-model="sortBy"
									desc
									:headers="headers" 
									:items="purchases" 
									hideNewItemButton
									@loadDataTable="toList"
								>
									<template v-slot:item.options="{ item }">
										<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
										<base-icon-btn v-if="item.statusId!==$Const.STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>
									</template>

									<template v-slot:item.status="{ item }">
										<template v-if="item.statusId===$Const.STATUS.REGISTERED">
											<span class="blue--text">{{item.status}}</span>
										</template>
										<template v-else-if="item.statusId===$Const.STATUS.APPROVED">
											<span class="success--text">{{item.status}}</span>
										</template>
										<template v-else>
											<span class="red--text">{{item.status}}</span>
										</template>
									</template>

									<template v-slot:item.paymentType="{ item }">
										<base-icon v-if="item.paymentType == 1" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'green darken-1'" tooltip="Efectivo">mdi-cash</base-icon>
										<base-icon v-else-if="item.paymentType === 2" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'info darken-1'" tooltip="Tajeta">mdi-credit-card-outline</base-icon>
										<template v-else-if="item.paymentType === 3">
											<base-icon v-if="item.creditStatusId===$Const.STATUS.CREDIT_PENDING" color="secondary" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
											<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CREDIT_COMPLETED" color="green darken-1" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
											<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CANCELLED" color="red" :tooltip="`Crédito ${item.creditStatus}`">mdi-account-cash</base-icon>
										</template>
									</template>

									<template v-slot:item.dateTime="{ item }">
										<base-form-date-list :dateTimeToShow="item.dateTime" 
											:showDialog="item.showDateTimeDialog"
											:formTitle="`Información compra: ${item.id}`"
											:headers="dateTimeHeaders"
											:items="item.dateTimeItems"
											@open="openDateTimeDialog(item)"
											@close="closeDateTimeDialog(item)"
										></base-form-date-list>
									</template>
								</base-data-table>
							</v-col>
						</v-row>
					</v-window-item>
					<v-window-item :value="2">
						<base-form-simple ref="PurchaseForm"
							formTitle="Compra"
							hideSaveButton
							@cancelButton="close"
						>
							<template v-slot:formContent>
								<v-container class="no-padding" fluid>
									<v-row justify="center">
										<v-col v-if="showReason" cols="12">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<v-icon color="red" left>mdi-alert-circle</v-icon>
												<strong>La compra fue anulada por el siguiente motivo: </strong> {{editedItem.reason}}
											</v-alert>
										</v-col>

										<!-- Detalles de la compra -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos de la compra"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.id" label="Nro. de Compra" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.status" label="Estado" readonly>
															</base-text-field>
														</v-col>

														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.voucherType" label="Comprobante" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="4">
															<base-text-field v-model="editedItem.voucherNumber" label="Comprobante N°" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="4">
															<base-text-field v-model="editedItem.authorizationNumber" label="Autorización N°" readonly>
															</base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos del proveedor -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos del proveedor"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row>
														<v-col cols="12" sm="8" md="8">
															<base-text-field v-model="editedItem.provider" label="Proveedor" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="4" md="4">
															<base-text-field v-model="editedItem.customCode" label="Código" readonly>
															</base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Detalles de los productos -->
										<v-col cols="12">
											<base-form-container
												title="Productos"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" align="center">
															<base-data-table ref="DtDetails"
																:headers="customDetailHeaders" 
																:items="details"
																sortBy="customCode"
																hideHeader
																:show-expand="showExpand"
																item-key="productId"
																elevation="0"
																showBorder
															>
																<template v-slot:expanded-item="{ item }">
																	<base-form-stock-details :ref="`StockForm${item.productId}`" :stockFormId="item.stockFormId" :title="`Detalles: ${item.product}`" :details="item.stockDetails" mode="ShowData">
																	</base-form-stock-details>
																</template>

																<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																	<transition v-if="item.stockFormId !== $Const.STOCK_FORM.GENERAL" name="fade" mode="out-in">
																		<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(!isExpanded)" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																		<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																	</transition>
																</template>

																<template v-slot:item.image="{ item }">
																	<div style="width:100px;">
																		<base-product-img v-model="item.image" :productId="item.productId"
																			class="my-2"
																			gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																			aspect-ratio="1.5"
																			contain
																		>
																		</base-product-img>
																	</div>
																</template>

																<template v-slot:item.quantity="{ item }">
																	<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																</template>

																<template v-slot:item.unitCost="{ item }">
																	<template v-if="editedItem.prorate == true">
																		Bs. {{ item.originalUnitCost }}
																	</template>
																	<template v-else>
																		Bs. {{ item.unitCost }}
																	</template>
																</template>

																<template v-slot:item.additionalUnitCost="{ item }">
																	<v-chip
																		color="secondary"
																		dark
																	>
																		+ {{ item.additionalUnitCost }}
																	</v-chip>
																</template>

																<template v-slot:item.newUnitCost="{ item }">
																	<v-chip
																		color="secondary"
																		dark
																	>
																			Bs. {{ item.unitCost }}
																	</v-chip>
																</template>

																<template v-slot:item.totalCost="{ item }">
																	Bs. {{ item.totalCost }}
																</template>

																<template v-slot:no-data>
																	<h3 class="red--text">*No hay productos agregados al detalle.</h3>
																</template>
															</base-data-table>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos finales -->
										<v-col cols="12" sm="6" order-sm="2" align="right">
											<base-form-container
												title="Datos finales"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:titleContent>
													<v-spacer></v-spacer>
													<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
														<v-icon dark>mdi-cart-variant</v-icon>
													</v-badge>
												</template>

												<template v-slot:content>
													<v-row align="center" justify="center" no-gutters>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Total a Pagar</strong>
																<p class="mt-2 mb-0">Bs. 
																	{{editedItem.totalCost}}
																</p>
															</h3>
														</v-col>
														<v-col cols="12" class="pt-4">
															<base-textarea outlined v-model="editedItem.observations" label="Observaciones" readonly>
															</base-textarea>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Gastos adicionales -->
										<v-col cols="12" sm="6" order-sm="1">
											<base-form-container
												title="Gastos adicionales"
												titleClasses="secondary white--text"
											>
												<template v-slot:content>
													<v-row>
														<v-col cols="12">
															<base-data-table
																:headers="additionalCostHeaders" 
																:items="additionalCostDetails"
																hideHeader
																elevation="0"
																showBorder
															>
																<template v-slot:item.cost="{ item }">
																	Bs. {{ item.cost }}
																</template>

																<template v-slot:no-data>
																	Sin gastos adicionales
																</template>
															</base-data-table>
														</v-col>
													</v-row>

													<v-expand-transition>
														<v-row v-if="additionalCostDetails.length > 0" align="center" justify="center">
															<v-col cols="12" sm="10" md="4" align="center">
																<v-switch label="Prorratear el gasto a los productos"
																	v-model="editedItem.prorate"
																	inset
																	readonly
																></v-switch>
															</v-col>

															<v-expand-transition>
																<v-col v-if="editedItem.prorate" cols="6" sm="6" md="4" align="center">
																	<h3 class="mt-6 mt-sm-0">
																		<strong>Por Producto</strong><p class="mt-2 mb-0">Bs. {{additionalUnitCost}}</p>
																	</h3>
																</v-col>
															</v-expand-transition>

															<v-col cols="6" sm="6" md="4" align="center">
																<h3 class="mt-6 mt-sm-0">
																	<strong>Total Gastos</strong>
																	<p class="mt-2 mb-0">Bs. 
																		{{additionalTotalCost}}
																	</p>
																</h3>
															</v-col>

															<v-expand-transition>
																<v-col v-if="editedItem.prorate" cols="12">
																	<v-alert
																		text
																		elevation="2"	
																		color="info"
																		class="text-justify"
																	>
																		<p><v-icon color="info" left>mdi-information</v-icon><b>¡IMPORTANTE!</b></p>
																		<p>Habilitando el prorrateo de gastos a los productos, <b>el monto Bs. {{additionalUnitCost}} será sumado al costo unitario del producto para generar un nuevo monto</b>, el cuál, se registrara como el <b>costo unitario final para el sistema.</b></p>
																		No obstante, este nuevo monto generado <b>no afectará al monto total del producto.</b>
																	</v-alert>
																</v-col>
															</v-expand-transition>
														</v-row>
													</v-expand-transition>
												</template>
											</base-form-container>
										</v-col>

										<!-- Formulario de pagos -->
										<v-col cols="12" sm="6" order-sm="3">
											<base-form-payment-type ref="PaymentForm" v-model="paymentData" :totalPrice="editedItem.totalCost" disabledFields :status="editedItem.creditStatusId">
											</base-form-payment-type>
										</v-col>
									</v-row>
								</v-container>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			title: 'Reporte de Compras',
			step: 1,
			sortBy: 'dateTime',
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Compra", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Proveedor", value: "provider" },
				{ text: "Código", value: "customCode" },
				{ text: "Tipo Comprobante", value: "voucherType" },
        { text: "Nro. Comprobante", value: "voucherNumber", sortable: false},
				{ text: "Fecha", value: "dateTime" },
				{ text: "Total Bs.", value: "totalCost", sortable: false},
				{ text: "Tipo Pago", value: "paymentType" },
        { text: "Estado", value: "status"}
			],
			purchases: [],
			detailHeaders: [
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: "Costo Unit.", value: "unitCost", sortable: false },
				{ text: "Costo Adicional", value: "additionalUnitCost", sortable: false },
				{ text: "Nuevo Costo Unit.", value: "newUnitCost", sortable: false },
				{ text: "Subtotal", value: "totalCost", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand' },
			],
			details: [],
			showExpand: false,
			voucherTypes:[
        {value: 1, text: "Factura"},
        {value: 2, text: "Recibo"},
        {value: 3, text: "Boleta"},
				{value: 4, text: "Ticket"},
				{value: 5, text: "Nota"}
			],
			providers:[],
			branchOffices:[],
			editedSearchItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
				providerId:'',
			},
			selectedItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
				providerId:'',
			},
			defaultSearchItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
				providerId:'',
			},
			loadingSearchForm: false,
			editedItem: {
				id: '',
				provider:'',
				customCode: '',
				voucherType:'',
        voucherNumber: '',
				authorizationNumber: '',
				totalCost: 0,
				observations: '',
				dateTime: '',
				statusId: 0,
				status: '',
				creditStatusId: 0,
				reason: '',
				hasAdditionalCosts: false,
				prorate: false,
      },
      defaultItem: {
       	id: '',
				provider:'',
				customCode: '',
				voucherType:'',
        voucherNumber: '',
				authorizationNumber: '',
				totalCost: 0,
				observations: '',
				dateTime: '',
				statusId: 0,
				status: '',
				creditStatusId: 0,
				reason: '',
				hasAdditionalCosts: false,
				prorate: false,
			},
			//PAYMENT DATA
			paymentData: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
			},
			//ADDITIONAL COSTS
			editedAdditionalCosts: {
				description: '',
				cost: ''
			},
			defaultAdditionalCosts: {
				description: '',
				cost: ''
			},

			additionalCostHeaders: [
				{ text: "Descripción", value: "description", sortable: false },
        { text: "Costo", value: "cost", sortable: false },
			],
			additionalCostDetails:[],

			//PURCHASE DATE TIMES
			dateTimeHeaders: [
				{ text: "Nro", value: "row", sortable: false },
				{ text: "Usuario", value: "userFullName", sortable: false },
				{ text: "Fecha y hora", value: "dateTime", sortable: false },
        { text: "Estado", value: "status", sortable: false },
			],

			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				purchaseId:'',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				purchaseId:'',
				fileName:'',
				fileContent:''
			}
		}
	},

	computed: {
		showMainReport(){
			if (this.step === 2 || this.purchases.length<=0) {
				return false;	
			}
			return true;
		},

		customDetailHeaders(){
			var excludes = [];
			
			this.showExpand = true;
			if (!this.details.some(x => x.stockFormId != this.$Const.STOCK_FORM.GENERAL)) {
				this.showExpand = false;
				excludes.push('data-table-expand');
			}

			if (!this.editedItem.prorate) {
				excludes.push('additionalUnitCost');
				excludes.push('newUnitCost');
			}

			if (excludes.length <= 0) {
				return this.detailHeaders;
			}
			return this.detailHeaders.filter(x => !excludes.includes(x.value));
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},

		calculateTotal(){
			var result=0.0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result=result + (element.unitCost * element.quantity);
			}
			return parseFloat(result).toFixed(2);
		},

		additionalTotalCost(){
			var result=0.0;
			for (let i = 0; i < this.additionalCostDetails.length; i++) {
				const element = this.additionalCostDetails[i];
				result = result + parseFloat(element.cost);
			}
			return parseFloat(result).toFixed(2);
		},

		additionalUnitCost() {
			if (this.additionalTotalCost <= 0) {
				return 0.00;
			}

			if (this.calculateTotalProducts <= 0) {
				return 0.00;
			}

			return (this.additionalTotalCost / this.calculateTotalProducts).toFixed(2);
		},

		showReason(){
			var result = this.step===2 && this.editedItem.statusId===this.$Const.STATUS.CANCELLED;
			return result;
		}
	},

	created() {
		this.listOfProviders();
		this.listOfBranchOffices();
  },

	methods: {
		dateRange(dates = []){
			if (dates == null) {
				this.editedSearchItem.startDate = this.defaultSearchItem.startDate;
				this.editedSearchItem.endDate = this.defaultSearchItem.endDate;
			}
			else {
				this.editedSearchItem.startDate = dates[0];
				this.editedSearchItem.endDate = dates[1];
			}
		},

		async toList(){
			if (!this.$refs.form.validate()) {
        return;
			}
			let me=this;
			try {
				me.SHOW_LOADING()
				me.purchases=[];
				me.loadingSearchForm=true;
				me.selectedItem = Object.assign({}, me.defaultSearchItem);
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedSearchItem.branchOfficeId,
					'startDate': me.editedSearchItem.startDate,
					'endDate': me.editedSearchItem.endDate,
					'providerId': !!me.editedSearchItem.providerId ? parseInt(me.editedSearchItem.providerId, 10) : 0 
				};
				await me.getObjectResponse('api/CompanyPurchase/GetReportByFilter', request).then(data => {
					if (data !== undefined) {
						me.purchases = me.addCustomProperties(data.purchases);
						me.selectedItem = Object.assign({}, me.editedSearchItem);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingSearchForm=false;
      }
		},

		addCustomProperties(originalPurchases) {
			let purchases = originalPurchases;
			purchases.map(x => {
				x.showDateTimeDialog = false;
				x.dateTimeItems = [];
			});
			return purchases;
		},

		async listOfBranchOffices(){
			let me=this;
			try {
        me.SHOW_LOADING()
				await me.getObjectResponse('api/BranchOffice/SelectMain', me.baseRequest).then(data => {
					if (data === undefined) {
						return;
					}
					me.branchOffices.push({text:data.branchOffice.visualName, value:data.branchOffice.id});
					me.editedSearchItem.branchOfficeId = data.branchOffice.id;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async listOfProviders(){
      let me=this;
			var providersArray=[];
			await me.getObjectResponse('api/Provider/Select', me.baseRequest).then(data => {
				if (data !== undefined) {
					providersArray = data.providers; 
					providersArray.map(function(x){
            me.providers.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanyPurchase/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data.purchase);
					me.details = me.setDetails(data.purchase.details);
					me.additionalCostDetails = me.setAdditionalCostDetails(data.purchase.additionalCosts);
					me.paymentData = Object.assign({}, 
					{
						paymentTypeId: data.purchase.paymentTypeId,
						amountPaid: data.purchase.amountPaid,
						cardNumber: data.purchase.cardNumber,
						nextPaymentDate: data.purchase.nextPaymentDate
					});
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId)
				result.push({
					productId: element.productId, 
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 
					originalUnitCost: parseFloat(element.originalUnitCost),
					additionalUnitCost: parseFloat(element.additionalUnitCost),
					unitCost: parseFloat(element.unitCost),
					totalCost: parseFloat(element.totalCost),

					stockFormId: stockFormId,
					stockDetails: stockDetails,
				});
			}
			return result;
		},

		parseStockDetails(stockDetails, stockFormId) {
			if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
				return [];
			}
			var result = JSON.parse(stockDetails)
			return result;
		},

		setAdditionalCostDetails(additionalCosts){
			var result = [];
			if (!this.editedItem.hasAdditionalCosts) {
				return result;
			}
			for (let i = 0; i < additionalCosts.length; i++) {
				const element = additionalCosts[i];
				result.push(element);
			}
			return result;
		},
		
    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			this.$refs.DtDetails.collapseAll();
			this.$refs.PurchaseForm.reset();
			this.$refs.PaymentForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
			this.details = [];

			this.editedAdditionalCosts = Object.assign({}, this.defaultAdditionalCosts);
			if (this.$refs.AdditionalCostsForm) {
				this.$refs.AdditionalCostsForm.reset();	
			}
			this.additionalCostDetails = [];
		},

		async openDateTimeDialog(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				item.dateTimeItems=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};

				if(item.statusId==me.$Const.STATUS.CANCELLED)
				{
					me.dateTimeHeaders.push({ text: "Motivo", value: "reason", sortable: false });
				}

				await me.getObjectResponse('api/CompanyPurchase/GetDatesById', request).then(data => {
					if (data !== undefined) {
						item.dateTimeItems = data.dateTimeList;
						item.showDateTimeDialog=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeDateTimeDialog(item) {
			item.showDateTimeDialog=false;
			item.dateTimeItems=[];
			if(item.statusId==this.$Const.STATUS.CANCELLED)
			{
				const index = this.dateTimeHeaders.indexOf(x => x.value == "reason");
				this.dateTimeHeaders.splice(index, 1);
			}
    },
		
		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.selectedItem.branchOfficeId,
					'startDate': me.selectedItem.startDate,
					'endDate': me.selectedItem.endDate,
					'providerId': !!me.selectedItem.providerId ? parseInt(me.selectedItem.providerId, 10) : 0
				};
				await me.getObjectResponse('api/CompanyPurchase/GetFilteredReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = me.title;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanyPurchase/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Compra ${me.reportFile.purchaseId}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>